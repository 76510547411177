import $ from 'jquery';
import _ from 'lodash';
import React, { ReactNode } from 'react';
import { withTranslation } from 'react-i18next';
import htmlParser from 'react-markdown/plugins/html-parser';
import ReactMarkdown from 'react-markdown/with-html';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import Annotation from '../../components/annotation/annotation';
import { ApplicationPicture } from '../../model/applicationPicture';
import { Disease } from '../../model/disease';
import WemedsLinkUtil, { WemedsLink } from '../../model/enums/wemedsLink';
import { IRootState } from '../../reducer';
import { openImageModal } from '../../reducer/globalModal/actions';
import { DiseaseTabEnum } from './disease-tab';
import './disease.scss';

export interface DiseaseTabContentProps extends RouteComponentProps<{}> {
    t: any;
    disease: Disease;
    applicationPictures?: ApplicationPicture[];
    hasSubscription?: boolean;
    markdown: string;
    tab: DiseaseTabEnum;

    fontSize?: number;
    showAnnotation?: boolean;
    openImageModal: (imageSrc: string) => void;
    onShowAnnotation: () => void;

    selectedHeadingIndex?: number;
}

export interface DiseaseTabContentState {
    selectedHeadingIndex: number;
    imageWithHover?: string;
    headings?: Heading[];
    highlights: WemedsReference[];
    canHandleScroll: boolean;
    markdownPadding: number | string;
}

interface Heading {
    title: string;
    index: number;
    scrollTop: number;
}

export interface WemedsReference {
    id: number;
    name: string;
    type: WemedsLink;
    code: string;
    isHighlight: boolean;
}

const parseHtml = htmlParser({
    isValidNode: node => node.type !== 'script',
    processingInstructions: [
        /* ... */
    ]
});

export class DiseaseTabContent extends React.Component<DiseaseTabContentProps, DiseaseTabContentState> {
    constructor(props: Readonly<DiseaseTabContentProps>, context?: any) {
        super(props, context);

        this.state = {
            selectedHeadingIndex: 0,
            highlights: [],
            canHandleScroll: true,
            markdownPadding: 0
        };
    }

    componentDidMount() {
        if (this.props.markdown != null) {
            const references = WemedsLinkUtil.getLinksFromMarkdown(this.props.markdown);
            this.setState({ highlights: references.filter(it => it.isHighlight) });
        }
        setTimeout(() => {
            this.getHeadings(true);
        }, 300);
    }

    componentWillReceiveProps(newProps: DiseaseTabContentProps): void {
        if (newProps.selectedHeadingIndex != null) {
            this.setState(
                {
                    selectedHeadingIndex: newProps.selectedHeadingIndex
                },
                () => {
                    this.scrollTo(newProps.selectedHeadingIndex!);
                }
            );
        }
    }

    private cleanMarkdown = (markdown: string): string => {
        let result: string = markdown;
        result = _.replace(result, /(\n# .*)|(^# .*)/, ''); // Remove disease title
        result = _.replace(result, /(\*\*`.*`\*\*)/g, ''); // Remove cid

        result = _.replace(result, /\-\-\-\-\-\-/g, ''); // Remove lines
        result = _.replace(result, /\-\-\-(?=\s\s)/g, ''); // Remove lines

        result = _.replace(result, /\+/g, '\\+'); // Remove unwanted bullet list
        result = _.replace(result, /(\d+\) {1})/g, '$1.'); // Fixed to show number with parentheses.

        result = _.replace(result, /#{4,6}\s{1,6}\*\*\*/g, '***'); // Fix exemplo de prescrição hospitalar

        result = _.replace(result, /\*\*(?=\W\W\s)(?!\*)/g, '** '); // Fix emoji conflict with bold

        result = _.replace(result, /#{4,6}/g, '###### '); // Fix headings

        result = _.replace(result, /\*\*\*(?!\s)/g, '**'); // Alter bold rule from some cases

        result = _.replace(result, /\t-/g, '  -'); // Fix subtopics

        result = _.replace(result, /\n\[/g, '\n> ['); // Fix image subtitle
        result = _.replace(result, /\n\*--/g, '\n> '); // Fix image subtitle
        result = _.replace(result, /--\*/g, ''); // Fix image subtitle

        result = _.replace(result, /\|(.{1,})/g, '$&\n'); // Fix prescrição médica
        result = _.replace(result, /\|/g, ''); // Fix prescrição médica
        result = _.replace(
            result,
            ':-:',
            '<div className="markdown__medical-prescription__header"><div className="markdown__medical-prescription__header__logo"></div><p className="markdown__medical-prescription__header__text">PRESCRIÇÃO MÉDICA</p></div>'
        ); // Fix prescrição médica
        result = _.replace(
            result,
            'Nome do Paciente e Endereço',
            '<p className="markdown__medical-prescription__header__subtitle">Nome do Paciente e Endereço</p>'
        ); // Fix prescrição médica
        result = _.replace(
            result,
            'Local, Data, Carimbo e assinatura do médico',
            '<div className="markdown__medical-prescription__footer"><p className="markdown__medical-prescription__footer__text">Local, Data, Carimbo e assinatura do médico</p></div>'
        ); // Fix prescrição médica

        // Colorful circles
        result = _.replace(result, /\(\(o\)\)/gi, '<span className="colorful-circle colorful-circle--o"></span>');
        result = _.replace(result, /\(\(r\)\)/gi, '<span className="colorful-circle colorful-circle--r"></span>');
        result = _.replace(result, /\(\(g\)\)/gi, '<span className="colorful-circle colorful-circle--g"></span>');
        result = _.replace(result, /\(\(y\)\)/gi, '<span className="colorful-circle colorful-circle--y"></span>');
        result = _.replace(result, /\(\(b\)\)/gi, '<span className="colorful-circle colorful-circle--b"></span>');

        const references = WemedsLinkUtil.getLinksFromMarkdown(result);
        if (references.length > 0) {
            result = result + '\n\n***\n\n #### **CONTEÚDOS RELACIONADOS** \n\n';
            references.forEach(reference => {
                result = result + ` ${reference.code} `;
            });
        }

        result = this.setInternalLinksInMarkdown(result);


        return result;
    };

    private setInternalLinksInMarkdown = (markdown: string) => {
        const regex = /\(\((MEDICINE_LEAFLET|DISEASE|PEDIATRIC_DOSES|MEDICAL_CALCULATORS|ILLUSTRADED_PROCEDURES|LABORATORIES|CRITERIA|SCORES|MICRO);(\d+);([^\)]*)\)\)/gi;
        markdown = _.replace(markdown, regex, '[$3](/$1/$2)');

        return _.replace(markdown, /;HIGHLIGHT/gi, '');
    };

    private scrollTo = (indexToGo: number) => {
        const headingElements = document.getElementsByClassName('markdown__heading');
        const headingElementsArray: Element[] = Array.from(headingElements);
        headingElementsArray.forEach((it, index) => {
            if (index === indexToGo) {
                this.setState({ selectedHeadingIndex: index, canHandleScroll: false });
                const element = $('div.disease-content__inside__markdown');
                const positionToScroll: number = element.scrollTop()! + $(it).position().top - 77;
                element.animate({ scrollTop: positionToScroll }, () => {
                    this.setState({
                        canHandleScroll: true
                    });
                });
            }
        });
    };

    private transformImageUri = (uri: string, children?: ReactNode, title?: string, alt?: string): string => {
        const { applicationPictures } = this.props;
        if (applicationPictures == null) {
            return '';
        }
        const picture: ApplicationPicture | undefined = _.find(applicationPictures, ['referenceName', uri]);
        if (picture != null) {
            return `data:image/png;base64,${picture.content}`;
        }
        return '';
    };

    private getHeadings = (setNewHeadings?: boolean): Heading[] => {
        const { tab } = this.props;
        const headingElements = document.getElementsByClassName('markdown__heading');
        const headingElementsArray: Element[] = Array.from(headingElements).filter(it => it.id === `heading-${_.lowerCase(tab)}`);
        const headings: Heading[] = headingElementsArray.map((it, index) => ({
            title: it.textContent!,
            index,
            scrollTop: (it as any).offsetTop
        }));
        if (headings.length === 0) {
            setTimeout(() => this.forceUpdate(), 200);
        }
        if (!this.state.headings || this.state.headings.length === 0 || setNewHeadings) {
            this.setState(
                {
                    headings
                },
                () => {
                    const headingElem = $(`#heading-item-${headings.length - 1}`)[0];
                    const headingContainerElem = $(`div.disease-content__inside__markdown`)[0];
                    if (headingElem && headingContainerElem) {
                        this.setState({
                            // markdownPadding: `calc(100vh - 80px - 50px - 60px - ${headingContainerElem.offsetHeight - headingElem.offsetTop + 200}px)`
                            markdownPadding: `calc(100vh - 80px - 50px - 60px)`
                        });
                    }
                }
            );
        }
        return headings;
    };
    private onImagePress = (image: string): void => {
        this.props.openImageModal(image);
    };
    private onImageHover = (src: string) => {
        if (this.state.imageWithHover === undefined) {
            this.setState({ imageWithHover: src });
        }
    };
    private onImageLeave = (): void => {
        this.setState({ imageWithHover: undefined });
    };
    private onScroll = async (event: any): Promise<void> => {
        const { scrollTop } = event.target;
        if (!this.state.headings || this.state.headings.length === 0 || !this.state.canHandleScroll) {
            return;
        }
        const currentHeading: Heading | undefined = this.state.headings.filter(it => it.scrollTop > scrollTop + 30)[0];
        const selectedHeadingIndex: number =
            currentHeading != null ? (currentHeading.index > 0 ? currentHeading.index - 1 : 0) : this.state.headings.length - 1;
        if (selectedHeadingIndex !== this.state.selectedHeadingIndex) {
            const headingElem = $(`#heading-item-${selectedHeadingIndex}`)[0];
            const headingContainerElem = $(`div.disease-content__menu`);
            const positionToScroll = (headingElem as any).offsetTop - 77;
            headingContainerElem.scrollTop(positionToScroll);
            this.setState({
                selectedHeadingIndex
            });
        }
    };

    render(): JSX.Element {
        const { tab, fontSize, showAnnotation, disease } = this.props;
        const { selectedHeadingIndex, markdownPadding } = this.state;
        const markdown = this.cleanMarkdown(this.props.markdown);
        const headings: Heading[] = this.getHeadings();
        return (
            <div id="disease-content" className="disease-content">
                <div className="disease-content__menu" style={showAnnotation ? {} : { minWidth: 231 }}>
                    {showAnnotation &&
                        <div className="disease-content__menu__expand-btn" onClick={this.props.onShowAnnotation} />}
                    {headings.map((it, index) => (
                        <a
                            id={`heading-item-${index}`}
                            key={index}
                            onClick={() => this.scrollTo(index)}
                            className={`disease-content__menu__item ${
                                selectedHeadingIndex === index ? 'disease-content__menu__item--selected' : ''
                            } ${showAnnotation ? 'disease-content__menu__item--hidden' : ''}`}
                        >
                            {!showAnnotation && (
                                <div className="disease-content__menu__item__content">
                                    <span className="disease-content__menu__item__text">{it.title}</span>
                                    {selectedHeadingIndex === index &&
                                        <div className="disease-content__menu__item__right-icon" />}
                                </div>
                            )}
                        </a>
                    ))}
                </div>
                <div className="disease-right-content__markdown">
                    {disease.hasFlashcards && <div className={'flashcard-info-container'}>
                        <span className={'flashcard-info-text'}>Aprenda muito mais sobre esse conteúdo praticando com perguntas e respostas</span>
                        <div className={'flashcard-info-button'} onClick={() => this.props.history.push(`/flashcard-disease/${disease.id}`)}>
                            <span className={'flashcard-info-button-text'}>Flashcards</span>
                        </div>
                    </div>}
                    <div className="disease-content__inside__markdown" onScroll={this.onScroll}>
                        {/* {this.state.highlights.map(reference => (
                        <InternalLinkHighlight key={reference.id} internalLink={reference} {...this.props} />
                    ))} */}
                        <ReactMarkdown
                            source={markdown}
                            className="markdown"
                            escapeHtml={false}
                            astPlugins={[parseHtml]}
                            renderers={{
                                paragraph: props => (
                                    <div className="markdown__paragraph" style={{ fontSize }}>
                                        {props.children.map(it => it)}
                                    </div>
                                ),
                                strong: props => <span
                                    className="markdown__strong">{props.children.map(it => it)}</span>,
                                heading: props => (
                                    <p id={`heading-${_.lowerCase(tab)}`} className="markdown__heading">
                                        {props.children.map(it => it)}
                                    </p>
                                ),
                                image: props => (
                                    <div
                                        className="markdown__image-container"
                                        onClick={() => this.onImagePress(props.src)}
                                        onMouseOver={() => this.onImageHover(props.src)}
                                        onMouseLeave={this.onImageLeave}
                                    >
                                        <img
                                            src={props.src}
                                            className="markdown__image"
                                            style={props.src === this.state.imageWithHover ? { opacity: 0.31 } : {}}
                                        />
                                        {props.src === this.state.imageWithHover &&
                                            <div className="markdown__image__zoom-icon" />}
                                    </div>
                                ),
                                emphasis: props => <span
                                    className="markdown__emphasis">{props.children.map(it => it)}</span>,
                                listItem: props => <li
                                    className="markdown__list-item">{props.children.map(it => it)}</li>,
                                code: props => <p className="markdown__code">{props.value}</p>,
                                blockquote: props => (
                                    <div className="markdown__blockquote">
                                        <div
                                            className="markdown__blockquote__content">{props.children.map(it => it)}</div>
                                    </div>
                                ),
                                link: props => {
                                    const isInternalLinkRegex = /(MEDICINE_LEAFLET|DISEASE|PEDIATRIC_DOSES|MEDICAL_CALCULATORS|ILLUSTRADED_PROCEDURES|LABORATORIES|CRITERIA|SCORES|MICRO)/gi;
                                    if (isInternalLinkRegex.test(props.href)) {
                                        const url = WemedsLinkUtil.replaceEnumNameByLink(props.href);
                                        const isContent = WemedsLinkUtil.isContent(props.href);
                                        const isMedicineLeaflet = WemedsLinkUtil.isMedicineLeaflet(props.href);
                                        const isAvailable =
                                            this.props.hasSubscription === true ||
                                            isMedicineLeaflet ||
                                            WemedsLinkUtil.isFreeItemOfType(props.href);
                                        return isAvailable ? (
                                            <a href={url}
                                               className={isContent ? 'markdown_content_link' : 'markdown_feature_link'}>
                                                {props.children.map(it =>
                                                    it.props?.value != null ? _.upperFirst(String(it.props?.value)) : ''
                                                )}
                                            </a>
                                        ) : (
                                            <span
                                                className={isContent ? 'markdown_content_link' : 'markdown_feature_link'}
                                                onClick={() => {
                                                    !isAvailable ? console.log('pode') : console.log('não pode');
                                                }}
                                            >
                                            {`🏆 `}
                                                {props.children.map(it =>
                                                    it.props?.value != null ? _.upperFirst(String(it.props?.value)) : ''
                                                )}
                                        </span>
                                        );
                                    } else {
                                        return (
                                            <a href={props.href} className="markdown__link">
                                                {props.children.map(it =>
                                                    it.props?.value != null ? _.upperFirst(String(it.props?.value)) : ''
                                                )}
                                            </a>
                                        );
                                    }
                                }
                            }}
                            transformImageUri={this.transformImageUri}
                        />
                        {headings.length > 1 && <div style={{ height: markdownPadding }} />}
                    </div>
                </div>
                <Annotation note={disease.note} diseaseId={{ id: disease.id }} showAnnotation={showAnnotation} />
            </div>
        );
    }
}

const mapStateToProps = ({ authentication }: IRootState) => ({});

const mapDispatchToProps = {
    openImageModal
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(DiseaseTabContent) as React.ComponentType<any>;
